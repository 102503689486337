import {
  defineStore,
} from 'pinia';
import {
  useUtilStore,
} from '@/store/util';

export const useNavStore = defineStore('nav', {
  state: () => ({
    isLightTheme: true,
  }),

  actions: {
    toggleTheme(): void {
      this.isLightTheme = !this.isLightTheme;
      useUtilStore().theme = this.isLightTheme ? 'light' : 'dark';

      this.setDocumentThemeClass();
    },
    setDocumentThemeClass(): void {
      if (this.isLightTheme) {
        document.documentElement.classList.remove('dark');
      } else {
        document.documentElement.classList.add('dark');
      }
    },
  },
  persist: {
    storage: window.sessionStorage,
  },
});
