/*
 * Ankur Mursalin
 *
 * https://encryptioner.github.io/
 *
 * Created on Fri Aug 05 2022
 */

import axios from 'axios';
import {
  type GetCompanyByWebsiteDto,
  type GetDynamicPagesDto,
  type GetEnrollmentsQueryDto,
  type GetHomeBannersDto,
  type GetSubjectsDto,
} from 'dto';
import {
  defineStore,
} from 'pinia';
import {
  coursePrograms,
} from 'utilities';
import {
  handleAxiosError,
} from '@/helpers';
import {
  useAuthStore,
} from '@/store/auth';
import type {
  ChangeTypeOfKeys,
  IClientCompany,
  IDynamicPage,
  IEnrollment,
  IHomeBanner,
  INav,
  ISubProgram,
  ISubject,
} from '@/types';

export const useBackendStore = defineStore('backend', {
  state: () => ({
    subjectsForProgram: {
    } as Record<string, ISubject[]>,
    subProgramsForProgram: {
    } as Record<string, ISubProgram[]>,

    enrolledCourses: [] as string[],
    homeBanners: [] as IHomeBanner[],
    dynamicPages: [] as IDynamicPage[],

    clientCompany: {
      _id: '',
    } as IClientCompany,
  }),
  getters: {
    // TODO: handle this dynamically
    dynamicRoutes: (state): INav[] => {
      const { name: appName } = state.clientCompany;

      if (appName === 'Fahad\'s Tutorial') {
        return [{
          path: 'https://shop.ft.education',
          title: 'Shop',
          alternateTitle: 'শপ',
        }];
      }

      return [];
    },
  },
  actions: {
    initOnAppMount(): void {
      this.fetchSubjectsForProgram();
      this.fetchSubProgramsForProgram();
      this.fetchHomeBanners();
      this.fetchDynamicPages();
    },
    initOnAuthChange(): void {
      const { user } = useAuthStore();

      if (!user) {
        this.enrolledCourses = [];
        return;
      }

      this.setEnrolledCourse(user.company);
    },
    async setEnrolledCourse(company: string): Promise<void> {
      const params: ChangeTypeOfKeys<GetEnrollmentsQueryDto, 'fields', string | undefined> = {
        fields: 'course',
        company,
      };

      try {
        const response = await axios.get<{docs : IEnrollment[]}>('/enrollments', {
          params,
        });

        this.enrolledCourses = response.data.docs.map((em) => em.course as string);
      } catch (e: any) {
        handleAxiosError(e);
      }
    },
    async fetchSubjectsForProgram(): Promise<void> {
      const subjectsForProgramPromise = async (program: string): Promise<void> => {
        try {
          const params: ChangeTypeOfKeys<GetSubjectsDto, 'filterBy' | 'fields', string | undefined> = {
            website: window.location.origin,
            fields: '_id,title,program,chapters',
            search: program,
            filterBy: 'program',
          };

          const response = await axios.get<{
            docs: ISubject[]; totalData: number;
          }>('/subjects', {
            params,
          });

          this.subjectsForProgram[program] = response.data.docs;
        } catch (e: any) {
          const errMessage = `Error in getting subjects for program ${program}`;
          handleAxiosError(e, errMessage);
        }
      };

      Promise.all(coursePrograms.map((p) => subjectsForProgramPromise(p)));
    },
    async fetchSubProgramsForProgram(): Promise<void> {
      const subProgramsForProgramPromise = async (program: string): Promise<void> => {
        try {
          const params: ChangeTypeOfKeys<GetSubjectsDto, 'filterBy' | 'fields', string | undefined> = {
            website: window.location.origin,
            fields: '_id,title,program',
            search: program,
            filterBy: 'program',
          };

          const response = await axios.get<{
            docs: ISubProgram[]; totalData: number;
          }>('/sub-programs', {
            params,
          });

          this.subProgramsForProgram[program] = response.data.docs;
        } catch (e: any) {
          const errMessage = `Error in getting subprograms for program ${program}`;
          handleAxiosError(e, errMessage);
        }
      };

      Promise.all(coursePrograms.map((p) => subProgramsForProgramPromise(p)));
    },
    async fetchHomeBanners(): Promise<void> {
      try {
        const params: ChangeTypeOfKeys<GetHomeBannersDto, 'fields', string | undefined> = {
          website: window.location.origin,
          fields: '_id,altText,imgSrcDesktop,imgSrcNonDesktop,link',
        };

        const response = await axios.get<{
          docs: IHomeBanner[], totalData: number
        }>('/home-banners', {
          params,
        });

        this.homeBanners = response.data.docs;
      } catch (e: any) {
        handleAxiosError(e);
      }
    },
    async fetchDynamicPages(): Promise<void> {
      try {
        const params: ChangeTypeOfKeys<GetDynamicPagesDto, 'fields', string | undefined> = {
          website: window.location.origin,
          fields: '_id,title,slug,courses',
        };

        const response = await axios.get<{
          docs: IDynamicPage[], totalData: number;
        }
        >('/dynamic-pages', {
          params,
        });

        this.dynamicPages = response.data.docs;
      } catch (e: any) {
        handleAxiosError(e);
      }
    },
    async fetchClientCompany(): Promise<void> {
      try {
        const params: GetCompanyByWebsiteDto = {
          website: window.location.origin,
        };

        const response = await axios.get<{
          company: IClientCompany;
        }
        >('/public/companies/website', {
          params,
        });

        this.clientCompany = response.data.company;
      } catch (e: any) {
        handleAxiosError(e, 'Error in fetching client company info');
      }
    },
  },
  persist: {
    storage: window.sessionStorage,
  },
});
