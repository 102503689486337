/*
 * Ankur Mursalin
 *
 * https://encryptioner.github.io/
 *
 * Created on Thu Aug 04 2022
 */

import type {
  AxiosResponse,
} from 'axios';
import axios from 'axios';
import type {
  CompanyIdDto,
  FacebookLoginDto,
  GoogleOAuth2Dto,
  GoogleOneTapDto,
} from 'dto';
import {
  defineStore,
} from 'pinia';
import {
  CONSTANTS,
  abbreviateName,
  handleAxiosError,
  profileImageSrc,
} from '@/helpers';
import type {
  IFacebookLoginInfo,
  IGoogleLoginInfo,
  ILoginInfo,
  ILoginType,
  IUser,
} from '@/types';

interface ILoginStorageData {
  token: string;
  loginType: ILoginType;
}

interface ISetAuthInfoParams extends ILoginStorageData {
  user?: IUser;
}

async function getLoggedInUser(token: string): Promise<IUser | undefined> {
  try {
    const resp = await axios.get('/web/auth/this', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return resp.data.user;
  } catch (e: any) {
    console.error(e);
    return undefined;
  }
}

// eslint-disable-next-line import/prefer-default-export
export const useAuthStore = defineStore('auth', {
  state: () => ({
    token: '',
    authCardTitle: '',
    loginType: '' as ILoginType,
    user: undefined as IUser | undefined,
  }),
  getters: {
    usernameAbbreviation: (state): string => abbreviateName(
      state.user?.firstName || state.user?.username,
      state.user?.lastName,
    ),
    userProfileImageSrc: async (state): Promise<string> => {
      const profileImage = await profileImageSrc(state.user);
      return profileImage;
    },
    loggedUser: (state): IUser => {
      const user = state.user || {
        _id: '',
        company: '',
        username: '',
        phoneNo: '',
        referralCode: '',
        role: 'content-admin',
        firstName: '',
        lastName: '',
        isActive: false,
        isSuspended: true,
        password: false,
        isEmailVerified: false,
        isPhoneVerified: false,
      };

      return user;
    },
  },
  actions: {
    async refreshUser(): Promise<void> {
      const user = await getLoggedInUser(this.token);
      this.user = user;
    },
    setAuthInfo({ loginType, token, user }: ISetAuthInfoParams): void {
      this.loginType = loginType;
      this.user = user;
      this.token = token;

      const storage = window.localStorage.getItem(CONSTANTS.STORAGE.TABBED_REMEMBER_ME)
        ? window.sessionStorage : window.localStorage;

      const loginStorageData: ILoginStorageData = {
        loginType,
        token,
      };

      storage.setItem(
        CONSTANTS.STORAGE.LOGIN_DATA,
        JSON.stringify(loginStorageData),
      );
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    },
    async login(loginType: ILoginType, token: string): Promise<void> {
      const user = await getLoggedInUser(token);
      this.setAuthInfo({
        loginType, token, user,
      });
    },
    async localLogin(): Promise<void> {
      const storage = window.localStorage.getItem(CONSTANTS.STORAGE.TABBED_REMEMBER_ME)
        ? window.sessionStorage : window.localStorage;

      const stringifiedLoginData = storage.getItem(CONSTANTS.STORAGE.LOGIN_DATA);

      if (!stringifiedLoginData) {
        return;
      }

      const { token, loginType } = JSON.parse(stringifiedLoginData) as ILoginStorageData;

      if (!token) {
        return;
      }

      const user = await getLoggedInUser(token);
      this.setAuthInfo({
        loginType: loginType as ILoginType, token, user,
      });
    },
    async googleLogin({
      code,
      credential,
      referredCode,
    }: IGoogleLoginInfo): Promise<boolean> {
      try {
        let response: AxiosResponse<ILoginInfo>;
        const website = window.location.origin;

        if (credential) {
          const payload: GoogleOneTapDto = {
            website,
            credential,
            referredCode,
          };

          response = await axios.post<ILoginInfo>(
            '/web/auth/login/google/one-tap',
            payload,
          );
        } else if (code) {
          const payload: GoogleOAuth2Dto = {
            website,
            code,
            referredCode,
          };

          response = await axios.post<ILoginInfo>(
            '/web/auth/login/google/oauth2',
            payload,
          );
        } else {
          return false;
        }

        const { user, token } = response.data;
        this.setAuthInfo({
          loginType: 'google', token, user,
        });
        return true;
      } catch (e: any) {
        handleAxiosError(e);
        return false;
      }
    },
    async fbLogin({
      token: fbToken,
      id,
      email,
      firstName,
      lastName,
      profileImage,
      referredCode,
    }: IFacebookLoginInfo): Promise<void> {
      try {
        const payload: FacebookLoginDto = {
          website: window.location.origin,
          token: fbToken,
          id,
          email,
          firstName,
          lastName,
          profileImage,
          referredCode,
        };

        const response = await axios.post<ILoginInfo>(
          '/web/auth/login/fb',
          payload,
        );

        const { user, token } = response.data;
        this.setAuthInfo({
          loginType: 'fb', token, user,
        });
      } catch (e: any) {
        handleAxiosError(e);
      }
    },
    async logout(isUnauthorized?: boolean): Promise<boolean> {
      if (isUnauthorized) {
        this.setAuthInfo({
          loginType: '', token: '',
        });
        return true;
      }

      try {
        const params: CompanyIdDto = {
          company: this.user?.company || '',
        };

        await axios.delete('/users/logout', {
          params,
        });

        this.setAuthInfo({
          loginType: '', token: '',
        });
        return true;
      } catch (e: any) {
        handleAxiosError(e);
        return false;
      }
    },
  },
  persist: {
    storage: window.sessionStorage,
  },
});
