export const CONSTANTS = {
  LOGS: {
    INFO: 'log-info',
  } as const,
  ROUTE: {
    DEFINED: {
      GUEST_HOME: '/',
      MEMBER_HOME: '/my-courses/enrolled',
      ACCOUNT_EDIT_PAGE: '/user/account/edit/profile',
    },
    /**
     * TODO:
     * 1. add more route and link it with main router file
     * 2. Use the alternate title as page title on view
     */
    home: {
      path: '/',
      title: 'Home',
      alternateTitle: 'হোম',
    },
    aboutUs: {
      path: '/about-us',
      title: 'About Us',
      alternateTitle: 'আমাদের সম্পর্কে',
    },
    privacyPolicy: {
      path: '/privacy-policy',
      title: 'Privacy Policy',
      alternateTitle: 'প্রাইভেসি পলিসি',
    },
    refundPolicy: {
      path: '/refund-policy',
      title: 'Refund Policy',
      alternateTitle: 'রিফান্ড পলিসি',
    },
    termsOfService: {
      path: '/terms-of-service',
      title: 'Terms of Service',
      alternateTitle: 'টার্মস এবং শর্তাবলি',
    },
    faq: {
      path: '/faq',
      title: 'FAQ',
      alternateTitle: 'সচরাচর জিজ্ঞাসিত প্রশ্ন',
    },
    publicFeedback: {
      path: '/#feedback-section',
      title: 'Feedback',
      alternateTitle: 'যোগাযোগ',
    },
    freeCourses: {
      path: '/free-courses',
      title: 'Free Courses',
      alternateTitle: 'ফ্রী কোর্স',
    },
    dynamicPages: {
      path: '/page',
      title: 'Special Batch',
      alternateTitle: 'স্পেশাল ব্যাচ',
    },
    allOurCourses: {
      path: '/our-courses/all',
      title: 'Our Courses',
      alternateTitle: 'সকল কোর্স',
    },
    allClass9Courses: {
      path: '/our-courses/programs?program=Class 9',
      title: 'Our Courses - Class 9',
      alternateTitle: 'নবম শ্রেণী',
    },
    allClass10Courses: {
      path: '/our-courses/programs?program=Class 10',
      title: 'Our Courses - Class 10',
      alternateTitle: 'দশম শ্রেণী',
    },
    allSSCCourses: {
      path: '/our-courses/programs?program=SSC',
      title: 'Our Courses - SSC',
      alternateTitle: 'এসএসসি',
    },
    allHSCCourses: {
      path: '/our-courses/programs?program=HSC',
      title: 'Our Courses - HSC',
      alternateTitle: 'এইচএসসি',
    },
    allAdmissionCourses: {
      path: '/our-courses/programs?program=Admission',
      title: 'Our Courses - Admission',
      alternateTitle: 'এডমিশন',
    },
    allSkillDevCourses: {
      path: '/our-courses/programs?program=Skill Development',
      title: 'Our Courses - Skill Development',
      alternateTitle: 'স্কিল ডেভেলপমেন্ট',
    },
    allRunningCourses: {
      path: '/our-courses/running',
      title: 'Our Courses - Running',
      alternateTitle: 'রানিং কোর্স',
    },
    allUpcomingCourses: {
      path: '/our-courses/upcoming',
      title: 'Our Courses - Upcoming',
      alternateTitle: 'আপকামিং কোর্স',
    },
    myEnrolledCourses: {
      path: '/my-courses/enrolled',
      title: 'My Courses',
      alternateTitle: 'আমার কোর্স',
    },
    myRunningCourses: {
      path: '/my-courses/running',
      title: 'My Running Courses',
      alternateTitle: 'রানিং কোর্স',
    },
    myUpcomingCourses: {
      path: '/my-courses/upcoming',
      title: 'My Upcoming Courses',
      alternateTitle: 'আপকামিং কোর্স',
    },
    news: {
      path: '/notice',
      title: 'Notice',
      alternateTitle: 'নোটিশ',
    },
    myAccount: {
      path: '/user/account/view',
      title: 'My Account',
      alternateTitle: 'আমার একাউন্ট',
    },
    userContact: {
      path: '/contact',
      title: 'Contact',
      alternateTitle: 'যোগাযোগ',
    },
  } as const,
  ROUTE_PREFIX: {
    DASHBOARD: '/dashboard',
  },
  STORAGE: {
    LOGIN_DATA: 'LOGIN_DATA',
    TABBED_REMEMBER_ME: 'TABBED_REMEMBER_ME',
  } as const,
} as const;

export const purchaseCourseVideoUrl = 'https://www.youtube.com/watch?v=qOIrjSBuCMc';
