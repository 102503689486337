import {
  defineStore,
} from 'pinia';
import type {
  IMediaModal,
} from '@/types';

export const useUtilStore = defineStore('util', {
  state: () => ({
    theme: '',
    videoModal: undefined as undefined | IMediaModal,
    imageModal: undefined as undefined | IMediaModal,
    youtubeVideoModal: undefined as undefined | IMediaModal,
    pdfModal: undefined as undefined | IMediaModal,
  }),
  persist: {
    storage: localStorage,
  },
});
