/* eslint-disable import/order */
import {
  createRouter,
  createWebHistory,
} from 'vue-router';
import {
  CONSTANTS,
  IS_DEVELOPMENT,
  trackGoogleTagEvent,
} from '@/helpers';
import {
  useStore,
} from '@/store';
import Bus, {
  NOTIFICATION,
} from '@/bus';
import {
  routes,
} from './routes';

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return new Promise((resolve) => {
        setTimeout(() => {
          const element = document.querySelector(to.hash);
          if (element) {
            element.scrollIntoView({
              behavior: 'smooth',
            });
            resolve();
          } else {
            resolve({
              top: 0,
            });
          }
        }, 500);
      });
    } if (savedPosition) {
      return savedPosition;
    }
    return {
      top: 0,
    };
  },
});

router.beforeEach(async (to, from, next) => {
  useStore.app.prevRoutePath = from.fullPath;
  useStore.app.isLoading = true;

  // ensuring user load from local storage attempted before routing
  await useStore.app.init();
  next();
});

router.afterEach((to, from, failure) => {
  useStore.app.isLoading = false;

  const { user } = useStore.auth;

  const userHighlight = user ? `${user.username} - ${user.phoneNo}` : 'N/A';

  trackGoogleTagEvent('page_change', 'change', `user = ${
    userHighlight
  } & to = ${
    to.meta.title
  } & from = ${
    from.meta?.title
  } && failure = ${
    !!failure
  }`);
});

router.beforeResolve((to, from, next) => {
  const { user } = useStore.auth;
  const { name: appName } = useStore.backend.clientCompany;

  let titleSuffix = user && IS_DEVELOPMENT
    ? ` | ${user.firstName}`
    : '';

  if (to.meta?.title) {
    titleSuffix = ` - ${to.meta?.title}${titleSuffix}`;
  }
  document.title = `${appName}${titleSuffix}`;

  if (!user && !to.meta?.isGuest && !to.meta?.isCommon && to.query.showPreview !== '1') {
    next(
      `/auth/${from.meta?.isCommon ? 'signup' : 'login'
      }?redirect=${encodeURIComponent(to.fullPath)}`,
    );
    return;
  }

  const shouldRedirectToMemberHome = to.meta?.isGuest;

  if (user) {
    const accountEditPath = '/user/account/edit';

    if (!user.fbLink
      && !to.path.startsWith(accountEditPath)
    ) {
      Bus.emit(NOTIFICATION.INFO, {
        message: 'অন্য পেজগুলো ঘুরে দেখার আগে অনুগ্রহ করে তোমার প্রোফাইলটি সম্পূর্ণ করো',
      });

      if (!from.path.startsWith(accountEditPath)) {
        next(`${accountEditPath}/profile`);
      } else {
        useStore.app.isLoading = false;
      }
      return;
    }

    if (shouldRedirectToMemberHome) {
      next(CONSTANTS.ROUTE.DEFINED.MEMBER_HOME);
      return;
    }
  }

  next();
});

export { routes };

export default router;
